<template>
  <div class="locale-changer my-2">
    <b-form-select
      v-model="$i18n.locale"
      :options="langs"
      size="sm"
      @change="onLanguageChanged()"
    ></b-form-select>
  </div>
</template>

<script>
import { BFormSelect } from 'bootstrap-vue';
import { mapGetters } from 'vuex';

export default {
  name: 'SelectLanguage',
  components: {
    BFormSelect,
  },
  data() {
    return {
      langs: [
        { value: 'he', text: 'עִברִית' },
        { value: 'en', text: 'English' },
        { value: 'ru', text: 'Русский' },
        // { value: 'ar', text: 'Arabic' },
      ],
    };
  },
  mounted() {
    const lang = window.localStorage.getItem('language');
    this.$i18n.locale = lang;

    this.changeLanguage();
  },
  computed: {
    ...mapGetters({
      isEmalonMode: 'GET_IS_EMALON_MODE',
    }),
  },
  methods: {
    async changeLanguage() {
      await this.$store.dispatch('UPDATE_LANGUAGE', this.$i18n.locale);
      await window.localStorage.setItem('language', this.$i18n.locale);
    },
    async reloadWithChangedLanguage() {
      const currentUrl = window.location.href;
      const url = new URL(currentUrl);
      const pl = /\+/g, search = /([^&=]+)=?([^&]*)/g, decode = (s) => decodeURIComponent(s.replace(pl, ' ')), query = window.location.search.substring(1);
      const urlParams = {};

      let match;
      // eslint-disable-next-line no-cond-assign
      while ((match = search.exec(query))) { urlParams[decode(match[1])] = decode(match[2]); }
      urlParams.lang = this.$i18n.locale;

      this.$router.push({ path: url.pathname, query: urlParams });
      setTimeout(() => {
        this.$router.go();
      }, 10);
    },
    async onLanguageChanged() {
      if (this.isEmalonMode) {
        this.reloadWithChangedLanguage();
      } else {
        this.changeLanguage();
      }
    },
  },
};
</script>

<style>
 .locale-changer {
    min-width: 120px;
 }
</style>
